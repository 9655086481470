.myTable {
    overflow-y: auto;
    position: relative;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #0000001a;
}
.myTable > table {
    width: 100%;
}
.myTable > table > thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    /* box-shadow: 10px 15px 20px #0000001a; */
    background-color: #F1F1F1;
}
.myTable > table > thead > tr > th {
    padding: 0;
    text-align: left;
}
@media (max-width: 992px) {
    .myTable > table > thead {
        display: table-header-group;
    }
}
/* @media (max-width: 768px) {
    .myTable > table > thead {
        display: none;
    }
} */
.myTable > table > tbody > tr {
    border-bottom: 1px solid rgb(240, 240, 240);
    cursor: default;
}
.myTable > table > tbody > tr > td {
    font-weight: bold;
}
.myTable > table > tbody > tr:hover {
    background-color: #f5f5f5;
}
.myTable > table > tbody > tr > td > .row > .label {
    font-weight: bold;
    display: none;
}
@media (max-width: 768px) {
    .myTable > table > tbody > tr > td > .row > .label {
        display: inline;
    }
}
