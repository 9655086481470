.header{
    background-color: #fafafa;
    font-family: "Roboto2";
}
.header img{
    height: 50px;
}
.header button{
    background-color: transparent;
    font-weight: bold;
    height: 30px;
    padding: 0px;
}
.header button>img{
    width: 15px;
    margin-left: 5px;
    margin-top: -2px;
}
.sideBar_menubtn{
    width: 30px !important;
    min-width: 30px !important;
    height: 30px !important;
    transform: rotate(-90deg);
    font-weight: bold;
    font-size: 16px;
    border-radius: 50px;
    margin: 36px -43px 0 15px;
    /* transition: transform 0.3s; */
    z-index: 1;
}
.sideBar{
    width: 20%;
    /* width: 300px; */
    min-width: 300px;
    height: calc(100vh - 112px);
    border-right: 2px solid #00000017;
}
.sideBar>.sidebar_header{
    box-shadow: -5px 0px 10px rgba(0,0,0,0.07);
    display: flex;
}
.sideBar>.sidebar_header>.profilePic{
    width: 50px;
    height: 50px;
    background-color: black;
    border-radius: 100px;
    margin-right: 15px;
}
.sideBar>.sidebar_header>.profileDet{
    align-self: center;
}
.sideBar>.sidebar_header>.profileDet>h5{
    margin: 0px 0 -7px 0;
}
.sideBar>.sidebar_body{
    display: flex;
    flex-direction: column;
}
.sideBar>.sidebar_body>a{
    font-size: 14px;
    margin: 0;
    font-weight: bold;
    padding: 5px 0 5px 20px;
    border-left: 3px solid transparent;
    transition: color 1s;
}
.sideBar>.sidebar_body>a:hover{
    color: #FEAC23;
    transition: color 0.1s;
}
.sideBar>.sidebar_body>a.active{
    color: #FEAC23;
    border-left: 3px solid #FEAC23;
}
.myMain{
    height: calc(100vh - 112px);
    min-width: 300px;
}