.parent {
  display: grid;
  grid-template-columns: repeat(5, 0.5fr);
  grid-column-gap: 7px;
  grid-row-gap: 7px;
}
.parent1 {
  display: grid;
  grid-template-columns: repeat(4, 0.5fr);
  grid-column-gap: 7px;
  grid-row-gap: 7px;
}
.parent2 {
  display: grid;
  grid-template-columns: repeat(6, 0.5fr);
  grid-column-gap: 7px;
  grid-row-gap: 7px;
}
.artist {
  font-family: "Roboto2";
}

.artist > .profile {
  margin-top: -30px;
  display: flex;
  flex-direction: column;
}
.artist > .desc {
  padding: 2% 5%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: max-content;
}
.artist > .desc > .inner {
  width: 75%;
  font-size: 1.4vw;
  margin: auto;
  background-color: antiquewhite;
}
.artist > .desc > .inner > .inner_text {
  padding: 2% 5%;
  font-family: "Roboto2";
  text-align: center;
  font-size: 1vw;
  line-height: 30px;
}
.artist > .desc > .inner > .buttons_div {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
  margin: 0% auto 2% auto;
}

.artist > .desc > .inner > .buttons_div > .buttons {
  padding: 2% 5%;
  border-radius: 50px;
  background-color: #d16a27;
  display: flex;
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-family: "Roboto2";
  font-size: 1vw;
}

.artist > .profile > .profilePic {
  width: 250px;
  height: 50px;
  background-color: #d16a27;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5vw;
  font-family: "Roboto Condensed", sans-serif;
}

.imageUploader > .profile {
  margin-top: -30px;
}
.imageUploader > .profile > .profilePic {
  width: 250px;
  height: 50px;
  background-color: #d16a27;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3vh;
  font-family: "Roboto Condensed", sans-serif;
}

.artistCounter {
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  height: 100px;
  background-color: #d16a27;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
}

._4cols-v2-admin {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  grid-template-areas: ".";
  -ms-grid-columns: 1fr 16px 1fr 16px 1fr 16px 1fr;
  grid-template-columns: repeat(4, 5vw);
  -ms-grid-rows: auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh
    auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto
    1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto
    1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto
    1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto
    1.28vh auto 1.28vh auto 1.28vh auto 1.28vh auto;
  /* grid-template-rows: auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto auto; */
  width: 50%;
  margin: auto;
}

.artistcardAdmin > .image {
  object-fit: cover;
  height: 100%;
}
.artistcardAdmin.addImageBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.artistcardAdmin.addImageBtn:hover {
  background-color: #fafafa;
  cursor: pointer;
}
.artistcardAdmin.addImageBtn > .addImage {
  width: 30% !important;
}
.imageUploader {
  font-family: "Robotocondensed";
}
.imageUploader .btn1 {
  background-color: transparent;
  margin: 5px 10px;
}
.imageUploader .btn1 > img {
  height: 15px;
  margin-top: -5px;
}
.imageUploader .textField {
  border: 2px solid black;
  background-color: transparent;
  border-radius: 5px;
  padding: 8px 15px;
  width: 100%;
  max-width: 250px;
}

.imageUploader .btn1.dark {
  background-color: #d16a27;
  border-radius: 10px;
  color: white;
  height: 40px;
  width: 150px;
  font-size: 2vh;
}
.imageUploader .checkBox {
  font-weight: normal;
}
.checkBox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkBox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid black;
}

/* On mouse-over, add a grey background color */
.checkBox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkBox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkBox .checkmark:after {
  left: 4px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid black;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.imageUploader .border-bottom {
  border-bottom: 2px solid #e7e7e7;
}
.imageUploader .border-right {
  border-right: 2px solid #e7e7e7;
}

.imageUploader p {
  font-family: "Roboto2";
  text-align: center;
  font-size: 1vw;
  line-height: 30px;
}
.imageUploader .paragraph {
  font-family: "Roboto2";
  text-align: center;
  line-height: 30px;
}
.imageUploader h4 {
  text-align: center;
}
.popUpfontsize {
  font-size: 1.1rem;
}

.carouselArrow {
  width: 2%;
}

.modalTutorial {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200, 0.7);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.containerModal2 {
  width: 90vw;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
}
.containerVideoModal {
  width: fit-content;
}
.modalCloseBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.modalCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.tutorialContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.bioArtistContainer {
  width: 100%;
  max-width: 1000px;

  padding: 40px 0 100px 0;
}
.uploadArtistImage {
  width: 210px;
  min-width: 20%;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  border: solid rgba(196, 196, 196, 0.5);
  padding: 30px;
}

.addMoodBoard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  padding: 30px;
}
.addMoodBoard img {
  max-width: 100px;
}

.elementaryContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 0.25fr 2fr;
  gap: 30px;
}

.elementaryBox {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 30px 10px 15px 10px;
  border-radius: 5px;
}

.elementaryBoxIcon {
  width: 15%;
  margin-bottom: 30px;
}

.elemtaryElementsBox:hover .elementaryBoxIcon {
  filter: brightness(0) saturate(100%) invert(80%) sepia(48%) saturate(4665%)
    hue-rotate(334deg) brightness(100%) contrast(97%);
}

.elemtaryElementsBox:hover .elementaryBoxText {
  color: #fa8e37;
}

.elementaryLibraryIcon {
  width: 22%;
}

.columns2Icon {
  width: 33%;
}

.columns3Icon {
  width: 50%;
}

.elementaryBoxText {
  text-align: center;
  font-size: 25px;
  line-height: 1.3;
}

.elemtaryElementsContainer {
  display: grid;
  border: 3px dashed;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
}

.elemtaryElementsBox {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 10px 15px 10px;
  border-radius: 5px;
}

.elementaryContainerMini {
  width: 100%;
}

.elementaryBoxMini {
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  padding: 10px 5px;
  gap: 10px;
  border-radius: 5px;
}

.elementaryBoxIconMini {
  width: 20%;
  max-width: 20px;
}
.buttonActionContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.buttonActionContainer div {
  display: flex;
  gap: 5px;
}
.elemtaryElementsBoxMini:hover .elementaryBoxIconMini {
  filter: brightness(0) saturate(100%) invert(80%) sepia(48%) saturate(4665%)
    hue-rotate(334deg) brightness(100%) contrast(97%);
}

.elemtaryElementsBoxMini:hover .elementaryBoxTextMini {
  color: #fa8e37;
}

.elementaryLibraryIconMini {
  width: 35%;
}

.columns2IconMini {
  width: 40%;
}

.columns3IconMini {
  width: 62%;
  max-width: 200%;
}

.elementaryBoxTextMini {
  text-align: center;
  font-size: 13px;
  margin: 0;
}

.elemtaryElementsContainerMini {
  display: grid;
  border: 3px dashed;
  grid-template-columns: 1fr 1fr; /* 6 equal columns */
}

.elemtaryElementsBoxMini {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 10px 15px 10px;
  border-radius: 5px;
}

.ideaBoardsButtons {
  margin-bottom: 15px;
  display: flex;
}

.myShannonNavDiv {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.addImageMyshannon {
  padding: 11px 20px 2px 20px;
  background-color: black;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.pIdeaBoard {
  font-size: 16px;
}

.welcomeTo {
  margin-left: 100px;
  margin-top: 50px;
}

.myHomeCont {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

.myHomeFavP {
  color: #b0b0b0;
  font-size: 0.65vw;
  font-weight: 500;
  margin-left: 20px;
  height: 40px;
}

.myHomeFavPAdd {
  color: #b0b0b0;
  font-size: 0.65vw;
  font-weight: 500;
  margin-left: 20px;
  height: 40px;
}

.myHomeSorting {
  opacity: 0;
  margin-bottom: 9px;
}

.myHomeSortingVisible {
  margin-bottom: 9px;
}

.myHomeFavDiv {
  height: 39vh;
  padding-left: 20px;
}

.myHomeButtonsAdd {
  margin-left: 0px;
  font-size: 0.6vw;
  padding: 6px 12px;
}

.uploadSection {
  display: flex;
  justify-content: space-between;
}
.inputFields {
  display: flex;
  flex-direction: column;
  gap: 20;
}
.socialMediaSelect,
.skllsSelect {
  margin: 10px 0;
}
.inputFields select {
  width: 23%;
  min-width: 200px;
  font-weight: bold;
  padding: 10px 10px;
  font-size: 14px;
  margin-bottom: 5px !important;
  cursor: pointer;
}
.socialMediaWebLink {
  width: 50% !important;
  margin-left: 2%;
  margin-bottom: 0 !important;
  font-size: 14px;
  margin-bottom: 5px !important;
}

.addMoreInArray {
  width: 38px;
  cursor: pointer;
}

.bio_desc_container {
  width: 100%;
  margin-bottom: 80px;
}
.labelsBio {
  font-size: 1.1rem;
  font-weight: 500;
}
#bio_desc {
  height: 200px;
  padding: 10px 20px;
  width: 100%;
}
.socialMediaContainerBio {
  display: flex;
}
.CrossButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  z-index: 1;
  cursor: pointer;
  background-color: gray;
}
.accessButtonsContainer {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  gap: 4px;
}
.accessButtons {
  width: 24px;
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  background-color: gray;
}
.accessButtonMain {
  background-color: #bc6127;
  width: 30px;
}
.emailInput {
  padding: 9px;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.emailInput:focus {
  border-color: #bc6127;
  outline: none;
}
.simpleAccessButtons {
  width: 24px;
  border-radius: 20%;
  padding: 4px;
  cursor: pointer;
  background-color: gray;
}
.agentEmailItem {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.removeAgentButton {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
}

.agentEmailList {
  list-style-type: none;
  padding: 0;
}

.agentEmailList li {
  display: flex;
  align-items: center;
}
.addedAgentsHeader {
  font-size: 20px;
  color: #d16a27;
  margin-bottom: 10px;
}

.noClientsMessage {
  color: #888;
  font-style: italic;
  margin-top: 10px;
}
.alreadyAddedEmails {
  width: 100%;
  text-align: left;
}
.agentButton {
  border-radius: 5px;
}
.orderArrow {
  width: 40px;
  z-index: 1000;
  cursor: pointer;
}
.orderElementArrow {
  width: 48px;
  z-index: 1000;
  cursor: pointer;
}
.imageUploaded {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
.imageView {
  position: relative;
}
.inputContainer {
  width: 48%;
}
.publishWorkInputContainer {
  width: 100%;
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}
@media (max-width: 576px) {
  .inputContainer {
    width: 100%;
  }
  .carouselArrow {
    width: 4%;
  }
}
